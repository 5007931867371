<template>
  <div class="user-form">
    <LoadingCard v-if="isLoading" />
    <md-card v-else class="meep-form">
      <slot name="header"></slot>
      <!-- le formulaire de création -->
      <md-card-content>
        <div class="meep-form-column">
          <!-- Statut -->
          <div class="meep-input">
            <md-field>
              <label for="scope">
                {{ $t("form.userType") }}
              </label>

              <md-select
                id="scope"
                v-model="$v.user.scope.$model"
                name="scope"
                :disabled="isImportEmloyee"
              >
                <md-option
                  v-for="(scope, index) in availableScopes"
                  :key="index"
                  :value="scope"
                >
                  {{ $$filters.formatScope(scope) }}
                </md-option>
              </md-select>
              <template v-if="$v.user.scope.$dirty">
                <span v-show="!$v.user.scope.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Gender -->
          <div v-if="isEmployee" class="meep-input">
            <md-field>
              <label>
                {{ $t("form.gender") }}
              </label>
              <md-select v-model="$v.user.gender.$model" name="company">
                <md-option
                  v-for="sex in sexes"
                  :key="sex.id"
                  :value="sex.value"
                >
                  {{ sex.value }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <!-- Nom -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('lastname') }">
              <label>{{ $t("form.lastName") }}*</label>
              <md-input
                v-model="$v.user.lastname.$model"
                name="lastname"
                type="lastname"
              >
              </md-input>
              <template v-if="$v.user.lastname.$dirty">
                <span v-show="!$v.user.lastname.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.user.lastname.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Prénom -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('firstname') }">
              <label>{{ $t("form.firstName") }}*</label>
              <md-input
                v-model="$v.user.firstname.$model"
                name="firstname"
                type="text"
              >
              </md-input>
              <template v-if="$v.user.firstname.$dirty">
                <span v-show="!$v.user.firstname.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.user.firstname.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Social Security Number -->
          <div v-if="isEmployee" class="meep-input">
            <md-field
              :class="{ 'md-invalid': errors.has('socialSecurityNumber') }"
            >
              <label>{{ $t("form.socialSecurityNumber") }}*</label>
              <md-input
                v-model="$v.user.socialSecurityNumber.$model"
                name="socialSecurityNumber"
                type="socialSecurityNumber"
              >
              </md-input>
              <template v-if="$v.user.socialSecurityNumber.$dirty">
                <span
                  v-show="!$v.user.socialSecurityNumber.required"
                  class="meep-error"
                >
                  {{ $t("meep-errors.required") }}
                </span>
                <span
                  v-show="!$v.user.socialSecurityNumber.numeric"
                  class="meep-error"
                >
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Birth Day -->
          <div v-if="isEmployee" class="meep-input">
            <md-datepicker v-model="$v.user.birthday.$model">
              <label>
                {{ $t("form.birthday") }}
              </label>
            </md-datepicker>
          </div>

          <!-- Email -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('email') }">
              <label>E-mail*</label>
              <md-input v-model="$v.user.email.$model" name="email" />
              <template v-if="$v.user.email.$dirty">
                <span v-show="!$v.user.email.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
                <span v-show="!$v.user.email.email" class="meep-error">
                  {{ $t("meep-errors.email") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Téléphone fixe -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
              <label>{{ $t("form.telFix") }}</label>
              <md-input
                v-model="$v.user.telfixe.$model"
                name="telfixe"
                type="tel"
              >
              </md-input>
              <template v-if="$v.user.telfixe.$dirty">
                <span v-show="!$v.user.telfixe.tel" class="meep-error">
                  {{ $t("meep-errors.tel") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Téléphone portable  -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('telportable') }">
              <label>{{ $t("form.telMobile") }}</label>
              <md-input
                v-model="$v.user.telportable.$model"
                name="telportable"
                type="tel"
              >
              </md-input>
              <template v-if="$v.user.telportable.$dirty">
                <span v-show="!$v.user.telportable.tel" class="meep-error">
                  {{ $t("meep-errors.tel") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Numéro et voie -->
          <div class="meep-input">
            <md-field>
              <label>{{ $t("form.address") }}</label>
              <md-input
                v-model="$v.user.adresse.$model"
                name="adresse"
                type="text"
              >
              </md-input>
            </md-field>
          </div>

          <!-- Ville -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('city') }">
              <label>{{ $t("form.city") }}</label>
              <md-input v-model="$v.user.city.$model" name="city" type="text">
              </md-input>
              <template v-if="$v.user.city.$dirty">
                <span v-show="!$v.user.city.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Country -->
          <div v-if="isEmployee" class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('country') }">
              <label>{{ $t("form.country") }}</label>
              <md-input
                v-model="$v.user.country.$model"
                name="country"
                type="country"
              >
              </md-input>
              <template v-if="$v.user.country.$dirty">
                <span v-show="!$v.user.country.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
              </template>
            </md-field>
          </div>

          <!-- Code postal -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>{{ $t("form.postalCode") }}</label>
              <md-input
                v-model="$v.user.codepostal.$model"
                name="codepostal"
                type="text"
              >
              </md-input>
              <template v-if="$v.user.codepostal.$dirty">
                <span v-show="!$v.user.codepostal.numeric" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
                <div v-if="!$v.user.codepostal.maxLength" class="meep-error">
                  {{
                    $t("meep-errors.max", {
                      max: $v.user.codepostal.$params.maxLength.max,
                    })
                  }}
                </div>
              </template>
            </md-field>
          </div>

          <!-- Department -->
          <div v-if="isEmployee" class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('department') }">
              <label>{{ $t("form.department") }}</label>
              <md-input
                v-model="$v.user.department.$model"
                name="department"
                type="text"
              >
              </md-input>
              <template v-if="$v.user.department.$dirty">
                <span v-show="!$v.user.department.numeric" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </md-field>
          </div>
        </div>

        <div class="meep-form-column">
          <!-- Hire Date -->
          <div v-if="isEmployee" class="meep-input">
            <md-datepicker v-model="$v.user.hireDate.$model">
              <label>
                {{ $t("form.hireDate") }}
              </label>
            </md-datepicker>
          </div>

          <div v-if="isEmployee" class="meep-input">
            <md-field>
              <label>
                {{ $t("form.contractType") }}
              </label>
              <md-select v-model="$v.user.contractType.$model" name="company">
                <md-option
                  v-for="contractType in contractTypes"
                  :key="contractType.id"
                  :value="contractType.value"
                >
                  {{ contractType.text }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div v-if="isEmployee" class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('salary') }">
              <label>{{ $t("form.salary") }}</label>
              <md-input
                v-model="$v.user.salary.$model"
                name="salary"
                type="text"
              >
              </md-input>
              <template v-if="$v.user.salary.$dirty">
                <span v-show="!$v.user.salary.numeric" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </md-field>
          </div>

          <div v-if="isEmployee" class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('trialPeriod') }">
              <label>{{ $t("form.trialPeriod") }}</label>
              <md-input
                v-model="$v.user.trialPeriod.$model"
                name="trialPeriod"
                type="text"
              >
              </md-input>
              <template v-if="$v.user.trialPeriod.$dirty">
                <span v-show="!$v.user.trialPeriod.numeric" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </md-field>
          </div>

          <template v-if="showCompanies">
            <p
              class="meep-form-title"
              :class="{ 'meep-input-top': isEmployee }"
            >
              {{
                $t(
                  isJEPA
                    ? "create-user.linkedUser"
                    : "create-user.linkedCompany"
                )
              }}
            </p>

            <div class="meep-input meep-select">
              <md-field>
                <ValidationProvider
                  v-slot="{ errors }"
                  style="width: 100%;"
                  name="companies"
                  rules="required"
                >
                  <label
                    class="meep-select-label"
                    :class="{ 'select-error': errors.length > 0 }"
                  >
                    {{
                      $t(
                        isJEPA
                          ? "create-user.linkUser"
                          : "create-user.linkCompany"
                      )
                    }}
                    *
                  </label>

                  <md-select
                    id="companies"
                    v-model="$v.userCompanies.$model"
                    name="companies"
                    multiple
                  >
                    <md-option
                      v-for="company in companies"
                      :key="company.id"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </md-option>
                  </md-select>

                  <p
                    v-show="
                      $v.userCompanies.$dirty && !$v.userCompanies.required
                    "
                    class="select-error select-error--small"
                  >
                    {{ $t("meep-errors.required") }}
                  </p>
                </ValidationProvider>
              </md-field>
            </div>

            <md-list class="meep-select-list">
              <md-list-item v-for="companyId in userCompanies" :key="companyId">
                <div class="md-list-item-text users-create__list-item">
                  {{ getCompanyName(companyId) }}
                </div>

                <md-button
                  class="md-icon-button md-list-action"
                  @click="removeCompany(companyId)"
                >
                  <md-icon class="md-primary"> cancel </md-icon>
                </md-button>
              </md-list-item>
            </md-list>
          </template>
          <div class="md-layout md-alignment-center-right create-button">
            <slot name="buttons">
              <md-button
                v-if="isCreateUser"
                class="md-raised md-primary"
                @click.native="createUser"
              >
                {{ $t("create-user.cta") }}
              </md-button>
            </slot>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import usersModel from "../../model/users";
import { mapGetters } from "vuex";
import companiesModel from "@/model/companies";
import LoadingCard from "@/components/LoadingCard";
import { USERS } from "@/constants";
import {
  required,
  numeric,
  email,
  requiredIf,
  maxLength,
} from "vuelidate/lib/validators";

import { ValidationProvider } from "vee-validate";
import { compareObjects, sortByKey } from "@/services/util";
import { alphaSpace, tel } from "../../services/vuelidate";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";

export default {
  name: "UserForm",

  mixins: [ForceLeaveMixin],

  components: {
    LoadingCard,
    ValidationProvider,
  },

  props: {
    userData: {
      type: Object,
      default: () => {},
    },

    oldUserData: {
      type: Object,
      default: () => {},
    },
  },

  validations: {
    user: {
      scope: { required },
      gender: {},
      firstname: {
        alphaSpace,
        required,
      },
      lastname: {
        alphaSpace,
        required,
      },
      socialSecurityNumber: {
        numeric,
        required: requiredIf(function() {
          return this.isEmployee;
        }),
      },
      birthday: {},
      email: { email, required },
      telfixe: { tel },
      telportable: { tel },
      adresse: {},
      city: { alphaSpace },
      country: { alphaSpace },
      codepostal: { numeric, maxLength: maxLength(5) },
      department: { numeric },
      company_ids: {
        required: requiredIf(function(){
        return !this.isJEPA;
      }) },
      hireDate: {},
      contractType: {},
      salary: { numeric },
      trialPeriod: { numeric },
    },

    userCompanies: {
      required: requiredIf(function(){
        return !this.isJEPA;
      }) },
  },

  data() {
    return {
      isLoading: false,
      sexes: [
        { value: "Masculin", id: 1 },
        { value: "Féminin", id: 2 },
      ],

      contractTypes: [
        { text: "Contrat à durée indéterminée", value: "CDI" },
        { text: "Contrat à durée déterminée", value: "CDD" },
        { text: "Contrat de travail temporaire", value: "CTT" },
      ],

      user: this.userData,

      oldUser: this.oldUserData,

      companies: [],

      userCompanies: [],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEPA"]),

    isCreateUser() {
      if (this.$route.path.includes("users/create")) {
        return true;
      }
      return false;
    },

    availableScopes() {
      if (this.isJEPA) {
        return [USERS.CLIENT];
      }

      if (this.isImport && !this.isImportEmloyee) {
        return [USERS.COLLAB, USERS.CLIENT];
      }

      const result = [USERS.EMPLOYEE];

      if (this.isAdmin || this.isCollab) {
        result.push(USERS.COLLAB, USERS.CLIENT);
      }

      return result;
    },

    isEmployee() {
      return this.user.scope === USERS.EMPLOYEE;
    },

    showCompanies() {
      if (this.isJEPA) {
        return this.user.scope === USERS.COLLAB;
      }

      return true;
    },

    isAnyModified() {
      return !compareObjects(this.user, this.oldUser);
    },

    confirmModalText() {
      switch (this.confirmationContext) {
        default:
          return {
            header: this.$t("users.view.modal-title"),
            body: () => {
              return this.$t("users.view.edit-modal-body");
            },

            question: this.$t("users.view.edit-modal-question"),
          };
      }
    },

    isImportEmloyee() {
      return this.$route.query.type === "employee";
    },
    isImport() {
      return this.$route.path.includes("import");
    },
  },

  async mounted() {
    this.isLoading = true;
    this.oldUser = { ...this.user };
    const companies = await companiesModel.getAll();
    this.companies = await sortByKey(companies, "name");
    this.isLoading = false;
    this.userCompanies = [...this.userData.company_ids];
  },

  watch: {
    userData: function() {
      this.user = this.userData;
      this.userCompanies = this.userData.company_ids;
    },

    oldUserData: function() {
      this.oldUser = this.oldUserData;
    },

    userCompanies: function() {
      this.userData.company_ids = this.userCompanies;
    },
  },

  methods: {
    goBack() {
      window.history.back();
    },

    removeCompany(companyId) {
      this.userCompanies = this.user.company_ids.filter(
        company => company !== companyId
      );
    },

    getCompanyName(companyId) {
      const company = this.companies.find(company => company.id === companyId);

      return company ? company.name : "";
    },

    async createUser() {
      const result = !this.$v.$invalid;
      console.log(this.$v);
      if (result) {
        try {
          if (!this.isEmployee) {
            this.user.birthday = null;
          }
          await usersModel.create(this.user);
          this.$emit("change-force-leave");
          this.$router.push("/dashboard/setting/users/");
          this.$toasted.global.AppSucces({
            message: this.$t("create-user.success-message"),
          });
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },
  },
};
</script>

<style lang="scss">
#app .users-create {
  &__form.md-layout-item {
    padding-bottom: 60px;
  }

  .select-error {
    color: var(--primary, #ff1744);

    &--small {
      font-size: 12px;
    }
  }

  &__list-item {
    color: var(--bg-primary);
  }

  &__card {
    padding: 0 25%;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .create-button {
    margin-top: 50px;
    .md-button {
      padding: 0 30px;
    }
  }
}

.user-form {
  .meep-form-title {
    margin-bottom: 18px;
  }
}
</style>
